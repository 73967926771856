<template>
  <div>
    <el-row :gutter="20" style="margin-top: -5px; margin-bottom: 38px">
      <el-col :span="24">
        <div v-if="[1, 2, 3, 5, 4].indexOf(courseType) === -1">
          <el-radio-group
            v-model="financeRadio"
            :disabled="disabled"
            size="default"
            @change="getDataList"
            style="margin: -2px -15px 10px -15px"
            text-color="#3B6EFB"
            fill="#FFFF"
          >
            <el-radio-button
              style="width: 50px; font-size: 20px"
              label="证券"
              value="证券"
            />
            <el-radio-button
              style="width: 50px; font-size: 20px"
              label="外汇"
              value="外汇"
            />
          </el-radio-group>
        </div>
      </el-col>
    </el-row>
    <div class="mian">
          <el-card >
            <div style="margin-bottom: 10px;">
              <div class="card-line" style="z-index: 99"></div>
              现有研究报告
            </div>
            <spread-table
              @highlightChange="highlightChange"
              :singelSelectRadio="fileId"
              :singelSelect="true"
              :smallType="true"
              v-loading="loading"
              :pageSize="pageSize"
              :currentPage="currentPage"
              :total="total"
              @clickButton="clickButton"
              @sizeChange="sizeChange"
              @currentChange="currentChange"
              :operation="operation"
              :upreport="true"
              :tableColumn="financeRadio == '证券' ? tableList : whTableList"
              :beforeRouteName="beforeRouteName"
              :disabledTeacher="disabledTeacher"
              :tableData="spreadTable"
            ></spread-table>
          </el-card>
      <el-row :gutter="20" style="margin-bottom: 10px">
        <el-col :span="24">
          <div style="max-height: 40vh">
            <el-row :gutter="20" style="margin-top: 20px">
              
              <el-col :span="6">
                <el-card class="box-card">
                  <div style="margin-bottom: 10px">
                    <div class="card-line"></div>
                    现有可用
                  </div>
                  <spread-table
                    :smallType="true"
                    :ref="table1"
                    v-loading="loadingUsa"
                    @handleSelectionChange="
                      handleSelectionChange($event, '现有可用')
                    "
                    :pageSize="pageSizeUsa"
                    :currentPage="currentPageUsa"
                    :total="totalUsa"
                    @sizeChange="sizeChangeUsa"
                    @currentChange="currentChangeUsa"
                    :selection="true"
                    :tableColumn="
                      financeRadio == '证券' ? tableListUsa : whTableListUsa
                    "
                    :tableData="spreadTableUsa"
                  ></spread-table>
                </el-card>
              </el-col>
              <el-col :span="3" style="margin-top: 130px">
                <div class="button-stock">
                  <el-button
                    size="mini"
                    style="width: 120px"
                    type="primary"
                    plain
                    :disabled="disabledTeacher"
                    @click="rightMove"
                    icon="el-icon-d-arrow-right"
                  ></el-button>
                </div>
                <div class="button-stock" style="margin: 20px 0">
                  <el-button
                    size="mini"
                    style="width: 120px"
                    type="primary"
                    plain
                    :disabled="disabledTeacher"
                    @click="leftMove"
                    icon="el-icon-d-arrow-left"
                  >
                  </el-button>
                </div>
                <div class="button-stock">
                  <el-form>
                    <el-form-item label="数量:">
                      <el-input
                        v-model="numInput"
                        size="mini"
                        controls-position="right"
                        :disabled="disabledTeacher"
                        placeholder="数量"
                        type="number"
                        style="width: 70px"
                      >
                      </el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
              <el-col :span="8">
                <el-card class="box-card">
                  <div style="margin-bottom: 10px">
                    <div class="card-line"></div>
                    调整目标
                  </div>
                  <el-button
                    size="mini"
                    type="primary"
                    @click="editUpreport"
                    :disabled="disabledTeacher"
                    style="margin: 10px 0;"
                    >编写调仓报告</el-button
                  >
                  <!-- <el-radio-group v-model="direction" class="ml-4" style="margin-left: 20px;">
                  <el-radio label="0" size="large">买</el-radio>
                  <el-radio label="1" size="large">卖</el-radio>
                </el-radio-group> -->
                  <!--              <spread-table :smallType="true" :ref="table2" v-loading="loadingAll" @handleSelectionChange="handleSelectionChange"-->
                  <!--                            :pageSize="pageSizeAll" :currentPage="currentPageAll" :total="totalAll"-->
                  <!--                            @sizeChange="sizeChangeAll" @currentChange="currentChangeAll"  :selection="true"-->
                  <!--                            :tableColumn="tableListAll" :tableData="spreadTableAll"></spread-table>-->
                  <spread-table
                    :smallType="true"
                    :ref="table2"
                    @handleSelectionChange="
                      handleSelectionChange($event, '调整目标')
                    "
                    :selection="true"
                    :currentPage="currentPageAll"
                    :tableColumn="
                      financeRadio == '证券' ? tableListAll : whTableListAll
                    "
                    :tableData="spreadTableAll"
                  >
                  </spread-table>
                </el-card>
              </el-col>
              <el-col :span="7">
                <el-card class="box-card">
                  <div style="margin-bottom: 10px">
                    <div class="card-line"></div>
                    现有目标仓位
                  </div>
                  <spread-table
                    :smallType="true"
                    v-loading="loadingPlan"
                    :tableHeight="nowHightTable"
                    :pageSize="pageSizePlan"
                    :currentPage="currentPagePlan"
                    :total="totalPlan"
                    @sizeChange="sizeChangePlan"
                    @currentChange="currentChangePlan"
                    :tableColumn="
                      financeRadio == '证券' ? tableListPlan : whTableListPlan
                    "
                    :tableData="spreadTablePlan"
                  ></spread-table>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
   

    <check-file
      :value="valueFile"
      :fileName="fileName"
      :diaStart="diaStart"
      @setClose="closeDia"
    ></check-file>
  </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from "@/components/SpreadTable";
import MiniTag from "@/components/MiniTag";
import ECharts from "@/components/Echarts";
import checkFile from "../upreport-manage/checkFile";
import { stockData } from "./stockData";
import { useRouter } from "vue-router";
import {
  clickButtons,
  getStockLists,
  sizeChanges,
  currentChanges,
  sizeChangePlans,
  handleSelectionChanges,
  currentChangePlans,
  getStockListPlans,
  sizeChangeUsas,
  currentChangeUsas,
  getStockListUsas,
  sizeChangeAlls,
  currentChangeAlls,
  getStockListAlls,
  rightMoves,
  leftMoves,
  editUpreports,
  highlightChanges,
} from "./stockMethods";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    MiniTag,
    ECharts,
    checkFile,
  },
  setup() {
    const router = useRouter();

    let data = reactive({
      // direction:null,
      courseType: 0,
      financeRadio: "证券",
      disabled: false,
      // table 属性
      tableList: [
        { name: "报告编号", text: "fileId" },
        { name: "报告名称", text: "name" },
        { name: "绑定标的", text: "tiedStockList" },
        { name: "报告类型", text: "fileTypeName" },
        { name: "状态", text: "fileStateName" },
      ],
      whTableList: [
        { name: "报告编号", text: "fileId" },
        { name: "报告名称", text: "name" },
        { name: "绑定币种", text: "currencyName" },
        { name: "报告类型", text: "fileTypeName" },
        { name: "状态", text: "fileStateName" },
      ],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      loading: true,
      spreadTable: [],
      operation: ["查看", "通过", "否决"],
      heightCharts: 300,

      tableListPlan: [
        { name: "代码", text: "internalsid" },
        { name: "简称", text: "name" },
        { name: "数量", text: "targetamount" },
      ],
      whTableListPlan: [
        { name: "币种", text: "name" },
        { name: "简称", text: "abbr" },
        { name: "数量", text: "targetAmount" },
        // { name: '买卖方向', text: 'directionName' },
      ],
      currentPagePlan: 1,
      pageSizePlan: 4,
      totalPlan: 0,
      loadingPlan: true,
      spreadTablePlan: [],
      tableListUsa: [
        { name: "代码", text: "marketSID" },
        { name: "简称", text: "name" },
      ],
      whTableListUsa: [
        { name: "币种", text: "name" },
        { name: "简称", text: "abbr" },
      ],
      currentPageUsa: 1,
      pageSizeUsa: 10,
      totalUsa: 0,
      loadingUsa: true,
      spreadTableUsa: [],
      beforeRouteName: "courseStock",
      tableListAll: [
        { name: "代码", text: "marketSID" },
        { name: "简称", text: "name" },
        { name: "数量", text: "num" },
      ],
      whTableListAll: [
        { name: "币种", text: "name" },
        { name: "简称", text: "abbr" },
        { name: "数量", text: "targetAmount" },
      ],
      currentPageAll: 0,
      pageSizeAll: 5,
      totalAll: 0,
      loadingAll: true,
      spreadTableAll: [],
      disabledTeacher: false,
      selectList: [],
      rightMoveList: [],
      numInput: 0,
      nowHightTable: 813,
      pageType: 2,
      filetype: 1,
      routeId: undefined,

      // 查看
      fileName: "",
      diaStart: false,
      valueFile: "",
      fileId: null,
    });
    let refs = "";
    const table1 = (el) => {
      refs = el;
    };
    let refs2 = "";
    const table2 = (el) => {
      refs2 = el;
    };

    onMounted(() => {
      data.courseType = JSON.parse(sessionStorage.getItem("courseType"));
      data.disabledTeacher =
        JSON.parse(sessionStorage.getItem("user")).userRoleName === "教师"
          ? true
          : false;
      getStockList();
      getStockListPlan();
      // getStockListUsa()
      data.loadingUsa = false;
      getStockListAll();
      // groupIndex()
    });

    let getDataList = () => {
      (data.fileId = null), (data.spreadTableUsa = []);
      data.totalUsa = 0;
      data.loadingUsa = false;
      data.disabled = true;
      getStockList();
      getStockListPlan();
      // getStockListUsa()
      data.loadingUsa = false;
      // getStockListAll()
    };
    let getStockList = () => {
      getStockLists(data);
    };
    let getStockListPlan = () => {
      getStockListPlans(data);
    };
    let getStockListUsa = () => {
      getStockListUsas(data);
    };
    let getStockListAll = () => {
      getStockListAlls(data);
    };

    let sizeChange = (val) => {
      sizeChanges(val, data);
    };
    let sizeChangePlan = (val) => {
      sizeChangePlans(val, data);
    };
    let sizeChangeUsa = (val) => {
      sizeChangeUsas(val, data);
    };
    let sizeChangeAll = (val) => {
      sizeChangeAlls(val, data);
    };

    let currentChange = (val) => {
      currentChanges(val, data);
    };
    let currentChangePlan = (val) => {
      currentChangePlans(val, data);
    };
    let currentChangeUsa = (val) => {
      currentChangeUsas(val, data);
    };
    let currentChangeAll = (val) => {
      currentChangeAlls(val, data);
    };

    let handleSelectionChange = (val, type) => {
      handleSelectionChanges(val, data, type);
    };

    let clickButton = (val) => {
      clickButtons(val, data);
    };
    let rightMove = () => {
      rightMoves(refs, data);
    };
    let leftMove = () => {
      leftMoves(refs2, data);
    };
    let editUpreport = () => {
      editUpreports(router, data);
    };
    let closeDia = () => {
      data.diaStart = false;
    };
    let highlightChange = (val) => {
      if (val == null || !val) {
        return;
      }
      data.fileId = val.fileId;
      highlightChanges(val, data);
    };

    return {
      ...toRefs(data),
      getStockList,
      getStockListPlan,
      getStockListUsa,
      getStockListAll,
      sizeChange,
      currentChange,
      clickButton,
      sizeChangePlan,
      currentChangePlan,
      sizeChangeUsa,
      currentChangeUsa,
      sizeChangeAll,
      currentChangeAll,
      handleSelectionChange,
      rightMove,
      leftMove,
      table1,
      table2,
      editUpreport,
      closeDia,
      highlightChange,
      getDataList,
    };
  },
});
</script>

<style scoped lang="less">
.mian {
  box-sizing: border-box;
  height: calc(100vh - 370px);
  padding-bottom: 10px;
  overflow-y: scroll;
  margin-top: -40px;
  padding-right: 10px;
  padding-bottom: 20px;
}
.box-card {
  /*height: 500px;*/
  min-height: 450px;

  .row-norm {
    line-height: 50px;

    .title-norm {
      color: #000;
      text-align: right;
      font-size: 13px;
    }

    .value-norm {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 5px;
  background: #faad14;
}

.button-stock {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

::v-deep.el-radio-button {
  .el-radio-button__inner {
    background: #ffff;
    color: #333;
    border: 0 !important;
  }
}
</style>
